<template>
  <div>
    <div v-if="plan.automatic_daily_backups == 'on'" class="settings-item settings__content-backup-2" :class="active == 'backup' ? 'active' : ''">

      <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

      <div class="settings__content-profile-top">
        <h5>Backups</h5>
      </div>

      <div class="kup">

        <div class="kup__top" v-if="!generateBackupBool">
          <p>
            Click the button to generate a new backup
          </p>
          <button @click="generateBackup">
            Generate
          </button>
        </div>

        <div v-else class="kup__top">
          <p>The download link will appear below. You can come back later.</p>
        </div>

        <div class="kup__cont">
          <h5>Automatic daily backups (14 days)</h5>
          <ul>
            <li v-for="(backup, i) of backups" :key="i">
              <p>{{ renderBackupName(backup) }}</p>
              <div class="kup__cont-links">
                <a href="#" @click.prevent="downloadBackup(backup)">Download</a>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div v-else class="settings-item settings__content-backup" :class="active == 'backup' ? 'active' : ''">
      <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">
      <div class="settings__content-profile-top">
        <h5>Backup</h5>
      </div>
      <div class="settings__backup-link">
        <p><span>Upgrade</span> your plan to enable automatic daily backups</p>
      </div>
    </div>

  </div>
</template>

<script>

  import {mapActions} from "vuex"
  import moment from 'moment'

  export default {
    props: ['active', 'user'],
    data: () => ({
      generateBackupBool: false,
      backups: []
    }),
    computed: {
      plan() {
        return this.$store.state.key.plan
      }
    },
    mounted() {
      this.backups = this.user.backups.reverse()
    },
    methods: {
      ...mapActions([
        "fetch_request"
      ]),
      close() {
        this.$emit('close')
      },
      renderBackupName(e) {
        let date = new Date(e)
        return moment(date).format('YYYY-MM-DD hh:mm')
      },
      async generateBackup() {

        this.generateBackupBool = true

        const res = {
          token: true,
          method: "POST",
          url: this.$w_api.EXPORT_BACKUP,
          body: ''
        }

        const req = await this.fetch_request(res)
        if (req.ok) {}

      },
      async downloadBackup(e) {
        let date = new Date(e)
        date = moment(date).format('YYYY-MM-DD hh:mm')
        this.$w_downloadFile(this, this.axios, `${this.$w_api.DOWNLOAD_BACKUP}?user_id=${this.user._id}&backup_name=${e}`, date + '.zip')
      }
    }
  }

</script>