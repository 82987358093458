<template>
    <div class="settings-item settings__content-short" :class="active == 'shortcuts' ? 'active' : ''">

        <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

        <div class="settings__content-profile-top">
            <h5>Shortcuts</h5>
        </div>

        <div class="item">

            <div class="box">
                <div class="box-left">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_0_191)">
                            <path
                                d="M15.7905 0.476196H4.20953C2.14767 0.476196 0.476196 2.14767 0.476196 4.20953V15.7905C0.476196 17.8523 2.14767 19.5238 4.20953 19.5238H15.7905C17.8523 19.5238 19.5238 17.8523 19.5238 15.7905V4.20953C19.5238 2.14767 17.8523 0.476196 15.7905 0.476196Z"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path d="M19.5238 12.7619H11.8095" stroke="white"
                                stroke-miterlimit="10" stroke-linecap="round"></path>
                            <path d="M7.4381 12.7619H0.476196" stroke="white"
                                stroke-miterlimit="10" stroke-linecap="round"></path>
                            <path
                                d="M7.43811 12.7619C7.41835 13.0611 7.46022 13.3611 7.56112 13.6434C7.66202 13.9258 7.8198 14.1844 8.0247 14.4033C8.2296 14.6221 8.47725 14.7966 8.75232 14.9159C9.02738 15.0352 9.32401 15.0967 9.62383 15.0967C9.92364 15.0967 10.2203 15.0352 10.4953 14.9159C10.7704 14.7966 11.0181 14.6221 11.223 14.4033C11.4278 14.1844 11.5856 13.9258 11.6865 13.6434C11.7874 13.3611 11.8293 13.0611 11.8095 12.7619"
                                stroke="white" stroke-miterlimit="10"
                                stroke-linecap="round"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_0_191">
                                <rect width="20" height="20" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <p>Inbox</p>
            </div>

            <label for="radio__toggle1" class="radio">
                <input id="radio__toggle1" type="checkbox" class="radio__toggle" value="inbox" v-model="shortcuts" @change="changeShortcuts">
            </label>

        </div>

        <div class="item">

            <div class="box">
                <div class="box-left">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_0_170)">
                            <path d="M7.04761 2.49524H8.05713H12.0571H12.9524"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path
                                d="M15.0381 2.49524H16.7048C17.0584 2.47202 17.4131 2.51901 17.7484 2.6335C18.0838 2.74799 18.3932 2.92771 18.6588 3.16232C18.9244 3.39692 19.1409 3.68176 19.2959 4.00043C19.4509 4.31909 19.5413 4.66529 19.5619 5.01905V6.80953H0.476196V5.01905C0.496797 4.66529 0.587204 4.31909 0.742207 4.00043C0.89721 3.68176 1.11374 3.39692 1.37933 3.16232C1.64492 2.92771 1.95431 2.74799 2.28967 2.6335C2.62503 2.51901 2.97974 2.47202 3.33334 2.49524H4.96191"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path
                                d="M19.5238 6.80951V17C19.5032 17.3538 19.4128 17.6999 19.2578 18.0186C19.1028 18.3373 18.8863 18.6221 18.6207 18.8567C18.3551 19.0913 18.0457 19.271 17.7104 19.3855C17.375 19.5 17.0203 19.547 16.6667 19.5238H3.29525C2.94165 19.547 2.58695 19.5 2.25158 19.3855C1.91622 19.271 1.60683 19.0913 1.34125 18.8567C1.07566 18.6221 0.859124 18.3373 0.704121 18.0186C0.549118 17.6999 0.458711 17.3538 0.43811 17V6.80951H19.5238Z"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path
                                d="M7.04764 1.40952V3.58095C7.04818 3.71357 7.02101 3.84484 6.96788 3.96635C6.91475 4.08786 6.83683 4.19694 6.73911 4.2866C6.64138 4.37625 6.52601 4.44451 6.40038 4.487C6.27475 4.52948 6.14163 4.54526 6.00954 4.53333C5.87671 4.54668 5.74255 4.53196 5.61578 4.49011C5.489 4.44826 5.37244 4.38023 5.27365 4.29043C5.17487 4.20062 5.09607 4.09105 5.04237 3.96883C4.98866 3.8466 4.96126 3.71445 4.96193 3.58095V1.40952C4.96126 1.27602 4.98866 1.14386 5.04237 1.02164C5.09607 0.899413 5.17487 0.789843 5.27365 0.700038C5.37244 0.610234 5.489 0.542202 5.61578 0.500356C5.74255 0.45851 5.87671 0.443785 6.00954 0.457137C6.27969 0.45561 6.54086 0.553975 6.74288 0.733328C6.84107 0.816021 6.91944 0.919715 6.97219 1.03675C7.02493 1.15379 7.05072 1.28118 7.04764 1.40952V1.40952Z"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path
                                d="M15.0381 1.40952V3.58095C15.0133 3.84034 14.8927 4.08117 14.6999 4.25643C14.5071 4.43169 14.2558 4.5288 13.9953 4.5288C13.7347 4.5288 13.4835 4.43169 13.2906 4.25643C13.0978 4.08117 12.9772 3.84034 12.9524 3.58095V1.40952C12.9519 1.2769 12.979 1.14563 13.0322 1.02412C13.0853 0.902608 13.1632 0.79353 13.2609 0.703874C13.3587 0.614219 13.474 0.545961 13.5997 0.503474C13.7253 0.460987 13.8584 0.445206 13.9905 0.457143C14.2636 0.455061 14.5279 0.553346 14.7334 0.733334C14.8295 0.817721 14.9065 0.92174 14.9591 1.03838C15.0116 1.15503 15.0386 1.28158 15.0381 1.40952V1.40952Z"
                                stroke="white" stroke-miterlimit="10"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_0_170">
                                <rect width="20" height="20" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <p>Today</p>
            </div>

            <label for="radio__toggle2" class="radio">
                <input id="radio__toggle2" type="checkbox" class="radio__toggle" value="today" v-model="shortcuts" @change="changeShortcuts">
            </label>

        </div>

        <div class="item">

            <div class="box">
                <div class="box-left">

                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_0_181)">
                            <path
                                d="M15.7905 0.476196H4.20953C2.14767 0.476196 0.476196 2.14767 0.476196 4.20953V15.7905C0.476196 17.8523 2.14767 19.5238 4.20953 19.5238H15.7905C17.8523 19.5238 19.5238 17.8523 19.5238 15.7905V4.20953C19.5238 2.14767 17.8523 0.476196 15.7905 0.476196Z"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path
                                d="M10 16.3238C13.4926 16.3238 16.3238 13.4925 16.3238 9.99999C16.3238 6.50744 13.4926 3.67618 10 3.67618C6.50747 3.67618 3.67621 6.50744 3.67621 9.99999C3.67621 13.4925 6.50747 16.3238 10 16.3238Z"
                                stroke="white" stroke-miterlimit="10"></path>
                            <path d="M6.78094 9.27618L9.40952 11.9048" stroke="white"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9.40955 11.9048L13.2191 8.09525" stroke="white"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_0_181">
                                <rect width="20" height="20" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <p>Assigned to me</p>
            </div>

            <label for="radio__toggle3" class="radio">
                <input id="radio__toggle3" type="checkbox" class="radio__toggle" value="assigned_to_me" v-model="shortcuts" @change="changeShortcuts">
            </label>

        </div>

        <div class="item">

            <div class="box">
                <div class="box-left">

                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.46 0.5H3.54C1.86105 0.5 0.5 1.86105 0.5 3.54V17.46C0.5 19.1389 1.86105 20.5 3.54 20.5H17.46C19.1389 20.5 20.5 19.1389 20.5 17.46V3.54C20.5 1.86105 19.1389 0.5 17.46 0.5Z"
                            stroke="white" stroke-miterlimit="10"></path>
                        <path
                            d="M4.68998 12.02C5.52946 12.02 6.20998 11.3395 6.20998 10.5C6.20998 9.66052 5.52946 8.98 4.68998 8.98C3.85051 8.98 3.16998 9.66052 3.16998 10.5C3.16998 11.3395 3.85051 12.02 4.68998 12.02Z"
                            stroke="white" stroke-miterlimit="10"></path>
                        <path
                            d="M10.5 12.02C11.3395 12.02 12.02 11.3395 12.02 10.5C12.02 9.66052 11.3395 8.98 10.5 8.98C9.66051 8.98 8.97998 9.66052 8.97998 10.5C8.97998 11.3395 9.66051 12.02 10.5 12.02Z"
                            stroke="white" stroke-miterlimit="10"></path>
                        <path
                            d="M16.31 12.02C17.1495 12.02 17.83 11.3395 17.83 10.5C17.83 9.66052 17.1495 8.98 16.31 8.98C15.4705 8.98 14.79 9.66052 14.79 10.5C14.79 11.3395 15.4705 12.02 16.31 12.02Z"
                            stroke="white" stroke-miterlimit="10"></path>
                    </svg>

                </div>
                <p>Medium priority</p>
            </div>

            <label for="radio__toggle4" class="radio">
                <input id="radio__toggle4" type="checkbox" class="radio__toggle" value="medium_priority" v-model="shortcuts" @change="changeShortcuts">
            </label>

        </div>

        <div class="item">

            <div class="box">
                <div class="box-left">

                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.46 0.5H3.54C1.86105 0.5 0.5 1.86105 0.5 3.54V17.46C0.5 19.1389 1.86105 20.5 3.54 20.5H17.46C19.1389 20.5 20.5 19.1389 20.5 17.46V3.54C20.5 1.86105 19.1389 0.5 17.46 0.5Z"
                            stroke="white" stroke-miterlimit="10"></path>
                        <path
                            d="M10.51 2.52H10.5C9.70471 2.52 9.06 3.16471 9.06 3.96V12.8C9.06 13.5953 9.70471 14.24 10.5 14.24H10.51C11.3053 14.24 11.95 13.5953 11.95 12.8V3.96C11.95 3.16471 11.3053 2.52 10.51 2.52Z"
                            stroke="white" stroke-miterlimit="10"></path>
                        <path
                            d="M10.5 18.48C11.2953 18.48 11.94 17.8353 11.94 17.04C11.94 16.2447 11.2953 15.6 10.5 15.6C9.70471 15.6 9.06 16.2447 9.06 17.04C9.06 17.8353 9.70471 18.48 10.5 18.48Z"
                            stroke="white" stroke-miterlimit="10"></path>
                    </svg>

                </div>
                <p>High priority</p>
            </div>

            <label for="radio__toggle5" class="radio">
                <input id="radio__toggle5" type="checkbox" class="radio__toggle" value="high_priority" v-model="shortcuts" @change="changeShortcuts">
            </label>

        </div>

    </div>
</template>

<script>

    import {mapActions} from "vuex"

    export default {
        props: ['active', 'user'],
        data: () => ({
            shortcuts: []
        }),
        mounted() {
            if (this.user.shortcuts) {
                this.user.shortcuts.forEach((e) => {
                    this.shortcuts.push(e.type)
                })
            }
        },
        computed: {
            lists() {
                return this.$store.state.projects.projects
            }
        },
        methods: {
            ...mapActions([
                "socket_add_project"
            ]),
            close() {
                this.$emit('close')
            },
            changeShortcuts() {

                let shortcuts = []
                let array = {
                    inbox: '#B86A6F',
                    today: '#F2A7A7',
                    assigned_to_me: '#E3B5B5',
                    medium_priority: '#E8CCA2',
                    high_priority: '#FC8785'
                }

                this.shortcuts.forEach(e => {
                    if (e == 'inbox') {
                        let project_id = this.user.shortcut_inbox
                        if (!this.user.shortcut_inbox) {
                            project_id = this.$w_decrypt(this.user._id)
                            this.socket_add_project({
                                name: 'Inbox',
                                _id: project_id
                            })
                        } else if (this.lists.length > 0) {
                            if (!this.lists.find(e => e._id == this.user.shortcut_inbox)) {
                                project_id = this.$w_decrypt(this.user._id)
                                this.socket_add_project({
                                    name: 'Inbox',
                                    _id: project_id
                                })
                            }
                        }
                        shortcuts.push({type: e, color: array[e], project_id: project_id})
                    } else {
                        shortcuts.push({type: e, color: array[e]})
                    }
                })

                this.$socket.emit('IN_Shortcuts', shortcuts)
            }
        }
    }

</script>