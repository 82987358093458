<template>
  <div class="settings-item settings__content-import" :class="active == 'import' ? 'active' : ''">

    <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

    <div class="settings__content-profile-top">
      <h5>Import</h5>
    </div>

    <div class="settings__import-item">
      <img :src="require('@/assets/static/images/todoist.svg')" alt="">
      <a href="#" @click="importData('microsoft')">
        Import from account
      </a>
    </div>

    <div class="settings__import-item">
      <img :src="require('@/assets/static/images/m1.svg')" alt="">
      <a href="#" @click="importData('microsoft')">
        Import from account
      </a>
    </div>

    <h6>
      Do you want to see another app in this section?
      Send us the app name below:
    </h6>

    <div class="settings__import-form">
      <input type="text" placeholder="App name">
      <button>Submit</button>
    </div>

    <p>
      Thank you, we'll try to prioritise this and email you once ready!
    </p>

  </div>
</template>

<script>

  export default {
    props: ['active', 'user'],
    methods: {
      close() {
        this.$emit('close')
      },
      importData(type) {
        let domain_url = process.env.VUE_APP_FRONTEND + '/'
        if (type == 'todoist') {
          window.open(`https://todoist.com/oauth/authorize?client_id=${process.env.VUE_APP_TODOIST_CLIENT_ID}&state=${process.env.VUE_APP_TODOIST_STATE}&scope=data:read&redirect_uri=${domain_url}todoist/`, type, "width=1000,height=800")
        } else if (type == 'microsoft') {
          window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=c40ab5ff-c75c-48ad-b4a7-a4242173b3dd&scope=https://graph.microsoft.com/Tasks.ReadWrite&response_type=code&redirect_uri=${domain_url}/microsoft`, type, "width=1000,height=800")
        }
      }
    }
  }

</script>