<template>
  <div class="settings-item settings__content-profile" :style="open ? 'overflow: hidden;height: 100vh;' : ''" :class="active == 'profile' ? 'active' : ''">
    
    <ChangeEmail :active="open" @close="open= ''" :user="user"></ChangeEmail>
    <ChangePassword :active="open" @close="open= ''" :user="user"></ChangePassword>

    <div id="wrap-box" class="wrap-box">

      <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

      <div class="settings__content-profile-top">
        <h5>Profile</h5>
      </div>

      <div class="settings__content-profile-up">
        <div>
          <label v-if="user.image">
            <input type="file" @input="fileUpload">
            {{ text_file ? text_file : 'Change photo' }}
          </label>
          <label v-else>
            <input type="file" @input="fileUpload">
            {{ text_file ? text_file : 'Upload photo' }}
          </label>
          <p>up to 4MB</p>
        </div>
        <button v-if="user.image" @click="avatarRemove">Remove photo</button>
      </div>

      <div class="settings__content-profile-inpit">
        <label>
          <span>Name</span>
          <input type="text" :value="user.name" id="change_name">
        </label>
        <button @click="saveUserName">Save</button>
      </div>

      <div class="settings__content-profile-inpit">
        <label>
          <span>Email</span>
          <input type="email" :placeholder="user.email" disabled="disabled">
        </label>
        <button @click="open_window('email')">Change</button>
        <button class="red" @click="verification" v-if="!user.verification">Verify</button>
      </div>

      <div class="settings__content-profile-inpit" v-if="user.password">
        <label>
          <span>Password</span>
          <input type="password" placeholder="*******" disabled="disabled">
        </label>
        <button @click="open_window('password')">Change</button>
      </div>

      <div class="settings__content-profile-inpit" v-else>
        <label>
          <span>Add Password</span>
          <input type="password" v-model="password">
        </label>
        <button @click="addPassword">Add</button>
      </div>

    </div>
  </div>
</template>

<script>

  import ChangePassword from '@/components/settings/ChangePassword'
  import ChangeEmail from '@/components/settings/ChangeEmail'
  import {mapActions, mapMutations} from "vuex"
  import passwordValidator from "password-validator"
  import File from '../../utils/aws'

  export default {
    name: 'Profile',
    props: ['active', 'user'],
    data: () => ({
      open: '',
      user_name: '',
      text_file: '',
      password: '',
      send_verification_button: true
    }),
    components: {
      ChangePassword,
      ChangeEmail
    },
    methods: {
      ...mapActions([
        "change_user_data"
      ]),
      ...mapMutations([
        "setMessage",
        "setChangeUserData"
      ]),
      avatarRemove() {
        let sizes = [70, 110]

        sizes.forEach(e => {
          const aws = new File()
          let data = aws.remove('Users/' + this.user._id + '/' + e + '/' + this.user.image)
        })

        this.setChangeUserData({
          type: 'change_image',
          image: ''
        })

        this.$socket.emit('IN_UserEdit', {
          type: 'change_image',
          image: ''
        })

      },
      open_window(type) {
        document.querySelector('.settings .settings-item').scrollTo({top: 0})
        this.open = type
      },
      close() {
        this.$emit('close')
      },
      saveUserName() {
        let $event = document.querySelector('#change_name')
        if ($event.value != '') {
          if ($event.value.length < 3) {
            this.setMessage({
              key_message: "warning",
              message: "Length should be 3 to 25"
            })
            return
          }
          if ($event.value.length > 25) {
            this.setMessage({
              key_message: "warning",
              message: "Length should be 3 to 25"
            })
            return
          }
          this.$socket.emit('IN_UserEdit', {
            type: 'change_name',
            name: $event.value
          })
          this.change_user_data({
            type: 'name',
            name: $event.value
          })
        }
      },
      async fileUpload(e) {
        if (e.target.files[0].size < 4500000) {
          if (e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png' || e.target.files[0].type == 'image/jpg') {
            this.text_file = 'Uploading...'

            function resizeImageToSpecificWidth(max, input, cb) {
              var data
              if (input.files && input.files[0]) {
                var reader = new FileReader()
                reader.onload = function (event) {
                  var img = new Image()
                  img.onload = function () {
                    if (img.width > max) {
                      var oc = document.createElement('canvas'), octx = oc.getContext('2d')
                      oc.width = img.width
                      oc.height = img.height
                      octx.drawImage(img, 0, 0)
                      if (img.width > img.height) {
                        oc.height = (img.height / img.width) * max
                        oc.width = max
                      } else {
                        oc.width = (img.width / img.height) * max
                        oc.height = max
                      }
                      octx.drawImage(oc, 0, 0, oc.width, oc.height)
                      octx.drawImage(img, 0, 0, oc.width, oc.height)
                      data = oc.toDataURL()
                    } else {
                      data = input.files[0]
                    }
                    cb(data)
                  }
                  img.src = event.target.result
                }
                reader.readAsDataURL(input.files[0])
              }
            }

            let sizes = [70, 110]
            sizes.forEach((f_size) => {
              resizeImageToSpecificWidth(f_size, e.target, async (data1) => {
                const aws = new File(data1)
                let data = aws.remove('Users/' + this.user._id + '/' + f_size + '/' + this.user.image)
                let uploadRequest = await aws.write('Users/' + this.user._id + '/' + f_size + '/' + e.target.files[0].name)

                uploadRequest.on('httpUploadProgress', (event) => {
                  this.text_file = 'Uploading...'
                })

                uploadRequest.send((err) => {
                  if (err) {
                    console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
                    console.log("FAILURE!!")
                  } else {

                    this.text_file = ''

                    localStorage.removeItem(this.user._id)

                    localStorage.setItem(this.user._id, data1)

                    this.$socket.emit('IN_UserEdit', {
                      type: 'change_image',
                      image: e.target.files[0].name
                    })

                  }
                })
              })
            })

          } else {
            alert('type file')
          }
        } else {
          alert('max size file 4mb')
        }

      },
      addPassword() {
        if (this.password) {

          var schema = new passwordValidator()
          schema
            .is().min(8)
            .is().max(100)
            .is().not().oneOf(this.$w_password)

          let valid = schema.validate(this.password, {list: true})
          if (valid.length > 0) {

            if (valid[0] == 'min') {
              this.setMessage({
                key_message: "warning",
                message: 'Minimum allowed number of characters 8',
              });
              return
            }
            if (valid[0] == 'max') {
              this.setMessage({
                key_message: "warning",
                message: 'Maximum allowed number of characters 100',
              });
              return
            }
            if (valid[0] == 'uppercase') {
              this.setMessage({
                key_message: "warning",
                message: 'Must be a big symbol',
              });
              return
            }
            if (valid[0] == 'lowercase') {
              this.setMessage({
                key_message: "warning",
                message: 'It should be small character',
              });
              return
            }
            if (valid[0] == 'digits') {
              this.setMessage({
                key_message: "warning",
                message: 'It should be number character',
              });
              return
            }
            if (valid[0] == 'spaces') {
              this.password_message = "Shouldn't be spaces symbol"
              this.setMessage({
                key_message: "warning",
                message: "Shouldn't be spaces symbol",
              });
              return
            }
            if (valid[0] == 'oneOf') {
              this.setMessage({
                key_message: "warning",
                message: 'This password is too easy to guess. Try using something stronger.',
              });
              return
            }
          }

          this.set_change_password()
          this.$socket.emit('IN_UserEdit', {
            type: 'add_password',
            password: this.password
          })

        } else {
          this.setMessage({
            key_message: "warning",
            message: "Field is empty.",
          });
        }
      },
      verification() {
        if (this.send_verification_button) {
          this.send_verification_button = false
          this.$socket.emit('IN_SendVerification')
          setTimeout(() => this.send_verification_button = true, 30000)
        }
      },
    }
  }

</script>