<template>
  <div class="change-email" :class="active == 'email' ? 'active' : ''">

    <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

    <div class="settings__content-profile-top">
      <h5>Profile Email</h5>
    </div>

    <label>
      <span>New email</span>
      <input type="text" v-model="new_email">
    </label>

    <label>
      <span>Confirm email</span>
      <input type="email" v-model="confirm_email">
    </label>

    <label>
      <div class="box">
        <span>TaskPal Password</span>
        <a href="#" @click.prevent="forgotPassword" :style="send_for_got_password ? 'opacity: 0.7' : ''">
          Forgot password?
        </a>
      </div>
      <input type="password" v-model="password">
    </label>

    <div class="change-email__buttons">
      <button @click="close" class="red">Cancel</button>
      <button @click="changeEmail">Change</button>
    </div>

  </div>
</template>

<script>

  import {mapMutations, mapActions} from "vuex"

  export default {
    props: ['active', 'user'],
    data: () => ({
      confirm_email: '',
      new_email: '',
      password: '',
      send_for_got_password: false
    }),
    methods: {
      ...mapActions([
        "ForGotPassword"
      ]),
      ...mapMutations([
        "setMessage"
      ]),
      async forgotPassword() {
        if (this.send_for_got_password) {
          return
        }

        this.send_for_got_password = true

        setTimeout(() => this.send_for_got_password = false, 30000)
        
        const res = {
          url: this.$w_api.ForGotPassword,
          body: {
            email: this.user.email
          }
        }

        const req = await this.ForGotPassword(res)

        if (req) {
          this.$w_alert(req, [{
            buttonText: 'OK',
            className: 'blue',
            callback: (close) => close()
          }])
        }

      },
      close() {
        this.$emit('close')
      },
      changeEmail() {
        console.log(0)
        if (this.new_email && this.confirm_email && this.password) {
          if (!this.$w_email_validate(this.new_email) && !this.$w_email_validate(this.confirm_email)) {
            this.setMessage({
              key_message: "warning",
              message: "Email no valid",
            })
            return
          }
          if (this.new_email == this.confirm_email) {
            if (this.new_email == this.user.email) {
              this.setMessage({
                key_message: "warning",
                message: "Email is the same with the previous one.",
              })
              return
            }
            this.$socket.emit('IN_UserEdit', {
              type: 'change_email',
              email: this.new_email,
              password: this.password
            })
            this.close()
          } else {
            this.setMessage({
              key_message: "warning",
              message: "Emails do not match",
            })
          }
        } else {
          this.setMessage({
            key_message: "warning",
            message: "Field is empty.",
          })
        }
      }
    }
  }

</script>