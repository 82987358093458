<template>
    <div class="settings-item settings__content-gen" :class="active == 'general' ? 'active' : ''">

        <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

        <div class="settings__content-profile-top">
            <h5>General</h5>
        </div>

        <div class="settings__content-profile-opt">
            <p>Language</p>
            <div class="settings__content-profile-opt-box">
                <select disabled @change="saveSettings('language')">
                    <option value="English">English</option>
                    <option value="Russian">Russian</option>
                </select>
            </div>
        </div>

        <div class="settings__content-profile-opt">
            <p>Homepage</p>
            <div class="settings__content-profile-opt-box">
                <select @change="saveSettings('shortcuts', $event)" :value="user.homepage._id || user.homepage.project_id || user.homepage.type">
                    <option value="inbox">Inbox</option>
                    <option value="today">Today</option>
                    <option value="assigned_to_me">Assigned to me</option>
                    <option value="medium_priority">Medium priority</option>
                    <option value="high_priority">High priority</option>
                    <optgroup label="Lists">
                        <option v-for="list of lists" :key="list._id" :value="list._id">{{ list.name }}</option>
                    </optgroup>
                    <optgroup label="Chats">
                        <option v-for="chat of chats" :key="chat._id" :value="chat._id">{{ chat.name }}</option>
                    </optgroup>
                </select>
            </div>
        </div>

        <div class="settings__content-profile-opt">
            <p>Date format</p>
            <div class="settings__content-profile-opt-box">
                <select @change="saveSettings('date_format', $event)" :value="user.date_format.date">
                    <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                    <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                </select>
            </div>
        </div>

        <div class="settings__content-profile-opt">
            <p>Time format</p>
            <div class="settings__content-profile-opt-box">
                <select @change="saveSettings('time_format', $event)" :value="user.date_format.time">
                    <option value="1:00 PM">1:00 PM</option>
                    <option value="13:00">13:00</option>
                </select>
            </div>
        </div>

        <div class="settings__content-profile-opt">
            <p>Start of the week</p>
            <div class="settings__content-profile-opt-box">
                <select @change="saveSettings('start_of_the_week', $event)" :value="user.date_format.start_of_the_week">
                    <option value="Monday">Monday</option>
                    <option value="Sunday">Sunday</option>
                </select>
            </div>
        </div>

        <div class="settings__content-profile-opt">
            <p>Expand subtasks by default</p>
            <div class="settings__content-profile-opt-box">
                <select @change="saveSettings('expand_subtask', $event)" :value="user.expand_subtask ? 'Enabled' : 'Disabled'">
                    <option value="Enabled">Enabled</option>
                    <option value="Disabled">Disabled</option>
                </select>
            </div>
        </div>

        <div class="settings__content-profile-opt">
            <p>Add tasks</p>
            <div class="settings__content-profile-opt-box">
                <select @change="saveSettings('new_task', $event)" :value="user.new_task ? 'top' : 'bottom'">
                    <option value="top">Top</option>
                    <option value="bottom">Bottom</option>
                </select>
            </div>
        </div>

    </div>
</template>

<script>

    import {mapActions} from "vuex"

    export default {
        props: ['active', 'user'],
        computed: {
            lists() {
                return this.$store.state.projects.projects
            },
            chats() {
                return this.$store.state.chats.chats
            }
        },
        methods: {
            ...mapActions([
                "socket_add_project"
            ]),
            close() {
                this.$emit('close')
            },
            saveSettings(type, $event) {

                let value = $event.target.value
                if (type == 'expand_subtask' || type == 'new_task') {
                    value = value == 'Enabled' || value == 'top' ? true : false
                }

                if (type == 'shortcuts') {

                    let array = []
                    
                    if (value == 'inbox') {
                        let project_id = this.user.shortcut_inbox
                        if (!this.user.shortcut_inbox) {
                            project_id = this.$w_decrypt(this.user._id)
                            this.socket_add_project({
                                name: 'Inbox',
                                _id: project_id,
                            })
                        } else if (this.lists.length > 0) {
                            if (!this.lists.find(e => e._id == this.user.shortcut_inbox)) {
                                project_id = this.$w_decrypt(this.user._id)
                                this.socket_add_project({
                                    name: 'Inbox',
                                    _id: project_id,
                                })
                            }
                        }
                        array = {type: 'inbox', color: "#B86A6F", project_id: project_id}
                    } else if (value == 'today') {
                        array = {type: 'today'}
                    } else if (value == 'assigned_to_me') {
                        array = {type: 'assigned_to_me'}
                    } else if (value == 'medium_priority') {
                        array = {type: 'medium_priority'}
                    } else if (value == 'high_priority') {
                        array = {type: 'high_priority'}
                    } else {
                        this.lists.forEach((project, i) => {
                            if (project._id == value) {
                                array = {type: 'project', _id: project._id}
                            }
                        })
                        this.chats.forEach((chat, i) => {
                            if (chat._id == value) {
                                array = {type: 'chat', _id: chat._id}
                            }
                        })
                    }

                    this.$socket.emit('IN_UserGeneralSettings', {
                        type: 'homepage',
                        field: array
                    })

                } else {
                    this.$socket.emit('IN_UserGeneralSettings', {
                        type: type,
                        field: value
                    })
                }

            }
        }
    }

</script>