<template>
  <div class="change-pass" :class="active == 'password' ? 'active' : ''">

    <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

    <div class="settings__content-profile-top">
      <h5>Profile Password</h5>
    </div>

    <label>
      <div class="box">
        <span>Old password</span>
        <a href="#" @click.prevent="forgotPassword" :style="send_for_got_password ? 'opacity: 0.7' : ''">
          Forgot password?
        </a>
      </div>
      <input type="password" v-model="old_password">
    </label>

    <label>
      <span>New password</span>
      <input type="password" v-model="new_password">
    </label>

    <label>
      <span>Confirm new password</span>
      <input type="password" v-model="confirm_password">
    </label>

    <div class="change-email__buttons">
      <button @click="close" class="red">Cancel</button>
      <button @click="saveUserPassword">Change</button>
    </div>

  </div>
</template>

<script>

  import passwordValidator from "password-validator"
  import {mapMutations, mapActions} from "vuex"

  export default {
    props: ['active', 'user'],
    data: () => ({
      old_password: '',
      new_password: '',
      confirm_password: '',
      send_for_got_password: false
    }),
    methods: {
      ...mapActions([
        "ForGotPassword"
      ]),
      ...mapMutations([
        "set_change_password",
        "setMessage"
      ]),
      async forgotPassword() {
        if (this.send_for_got_password) {
          return
        }

        this.send_for_got_password = true

        setTimeout(() => this.send_for_got_password = false, 30000)
        
        const res = {
          url: this.$w_api.ForGotPassword,
          body: {
            email: this.user.email
          }
        }

        const req = await this.ForGotPassword(res)

        if (req) {
          this.$w_alert(req, [{
            buttonText: 'OK',
            className: 'blue',
            callback: (close) => close()
          }])
        }

      },
      close() {
        this.$emit('close')
      },
      saveUserPassword() {
        if (this.old_password && this.new_password && this.confirm_password) {
          if (this.new_password == this.confirm_password) {

            var schema = new passwordValidator()
            schema
              .is().min(8)
              .is().max(100)
              .is().not().oneOf(this.$w_password)

            let valid = schema.validate(this.new_password, {list: true})
            if (valid.length > 0) {

              if (valid[0] == 'min') {
                this.setMessage({
                  key_message: "warning",
                  message: 'Minimum allowed number of characters 8'
                })
                return
              }

              if (valid[0] == 'max') {
                this.setMessage({
                  key_message: "warning",
                  message: 'Maximum allowed number of characters 100'
                })
                return
              }

              if (valid[0] == 'uppercase') {
                this.setMessage({
                  key_message: "warning",
                  message: 'Must be a big symbol'
                })
                return
              }

              if (valid[0] == 'lowercase') {
                this.setMessage({
                  key_message: "warning",
                  message: 'It should be small character'
                })
                return
              }

              if (valid[0] == 'digits') {
                this.setMessage({
                  key_message: "warning",
                  message: 'It should be number character'
                })
                return
              }

              if (valid[0] == 'spaces') {
                this.password_message = "Shouldn't be spaces symbol"
                this.setMessage({
                  key_message: "warning",
                  message: "Shouldn't be spaces symbol"
                })
                return
              }

              if (valid[0] == 'oneOf') {
                this.setMessage({
                  key_message: "warning",
                  message: 'This password is too easy to guess. Try using something stronger.'
                })
                return
              }

            }

            this.set_change_password()

            this.$socket.emit('IN_UserEdit', {
              type: 'change_password',
              new_password: this.confirm_password,
              password: this.old_password
            })

            this.close()

          } else {
            this.setMessage({
              key_message: "warning",
              message: "Passwords do not match"
            })
          }
        } else {
          this.setMessage({
            key_message: "warning",
            message: "Field is empty.",
          });
        }
      }
    }
  }

</script>