<template>
  <main class="main-settings">
    <div class="settings-section">
      <div class="container-fluid p-0">
        <div class="settings">
          
          <div class="settings__top">
            <a href="#" @click.prevent="cancel" class="back">
              <img :src="require('@/assets/static/images/arrow_back.svg')" alt="">
            </a>
            <span v-html="$w_user_image(user, 110)"></span>
            <h5>{{ user.name }}</h5>
          </div>

          <div class="settings__content">
            <div class="settings__content-general">
              <ul>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'profile'">
                    <img :src="require('@/assets/static/images/person.svg')" alt="">
                    <p>Profile</p>
                  </a>
                  <Profile :active="active" @close="close" :user="user"></Profile>
                </li>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'general'">
                    <img :src="require('@/assets/static/images/apps.svg')" alt="">
                    <p>General</p>
                  </a>
                  <General :active="active" @close="close" :user="user"></General>
                </li>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'theme'">
                    <img :src="require('@/assets/static/images/gridicons_themes.svg')" alt="">
                    <p>Theme</p>
                  </a>
                  <Theme :active="active" @close="close" :user="user"></Theme>
                </li>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'shortcuts'">
                    <img :src="require('@/assets/static/images/switch_access_shortcut.svg')" alt="">
                    <p>Shortcuts</p>
                  </a>
                  <Shortcuts :active="active" @close="close" :user="user"></Shortcuts>
                </li>

                <!-- <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'app_download'">
                    <img :src="require('@/assets/static/images/App-downloads.svg')" alt="">
                    <p>App downloads</p>
                  </a>
                </li> -->

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'usage'">
                    <img :src="require('@/assets/static/images/Usage.svg')" alt="">
                    <p>Usage</p>
                  </a>
                  <Usage :active="active" @close="close" :user="user"></Usage>
                </li>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'upgrade'">
                    <img :src="require('@/assets/static/images/Upgrade.svg')" alt="">
                    <p>Upgrade</p>
                  </a>
                  <Upgrade :active="active" @close="close" :user="user"></Upgrade>
                </li>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'import'">
                    <img :src="require('@/assets/static/images/Import.svg')" alt="">
                    <p>Import</p>
                  </a>
                  <Import :active="active" @close="close" :user="user"></Import>
                </li>

                <li>
                  <a class="settings-btn" href="#" @click.prevent="active = 'backup'">
                    <img :src="require('@/assets/static/images/Backup.svg')" alt="">
                    <p>Backup</p>
                  </a>
                  <Backup :active="active" @close="close" :user="user"></Backup>
                </li>

              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>

  document.head.querySelector("[name~=theme-color][content]").content = '#fff'

  import Profile from '@/components/settings/Profile'
  import General from '@/components/settings/General'
  import Shortcuts from '@/components/settings/Shortcuts'
  import Usage from '@/components/settings/Usage'
  import Upgrade from '@/components/settings/Upgrade'
  import Import from '@/components/settings/Import'
  import Backup from '@/components/settings/Backup'
  import Theme from '@/components/settings/Theme'

  export default {
    name: 'SettingComponent',
    data: () => ({
      active: ''
    }),
    mounted() {
      document.querySelector('.content').classList.remove('filter-blur--avatar-menu')
      document.head.querySelector("[name~=theme-color][content]").content = '#fff'
      this.active = this.$route.query.tab || ''
    },
    computed: {
      user() {
        return this.$store.state.key.user
      },
      lists() {
        return this.$store.state.projects.projects
      },
    },
    components: {
      Profile,
      General,
      Shortcuts,
      Usage,
      Upgrade,
      Import,
      Backup,
      Theme
    },
    methods: {
      close() {
        this.active = ''
      },
      cancel() {
        if (this.$route.query.plan_id) {
          this.$router.push('/project/' + this.lists[0]._id)
        } else {
          this.$router.go(-1)
        }
      }
    }
  }

</script>