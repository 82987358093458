<template>
    <div class="settings-item settings__content-usage" :class="active == 'usage' ? 'active' : ''">

        <img @click="close" :src="require('@/assets/static/images/arrow_back.svg')" alt="">

        <div class="settings__content-profile-top">
            <h5>Usage - {{this.getPlan.name}} plan</h5>
        </div>

        <ul>
            <li v-for="item in usageList" :key="item.name">
                {{item.name}} ({{item.max ? 'used/allowed' : 'allowed'}})
                <span>{{item.current}}{{item.max ? '/' + item.max : ''}}</span>
            </li>
        </ul>

        <label>
            Show disk usage in sidebar
            <input type="checkbox" @click="toggleShowDiskUsageInSidebar" :checked="showDiskUsageInSidebar">
        </label>

    </div>
</template>

<script>

    import {mapGetters, mapActions} from "vuex"

    export default {
        props: ['active', 'user'],
        data: () => ({
            showDiskUsageInSidebar: false
        }),
        mounted() {
            setTimeout(() => this.showDiskUsageInSidebar = this.user.show_disk_usage, 500)
        },
        computed: {
            ...mapGetters([
                "getChats",
                "getProjects",
                "getTeams",
                "getUserAllFileSize",
                "getUserUsedFileSize",
                "getPlan"
            ]),
            usageList() {
                let plan = this.getPlan
                let arr = []
                arr.push(this.createUsageSingle('Members', this.getTeams.length, plan.max_users_team))
                arr.push(this.createUsageSingle('Lists', this.getProjects.length, plan.lists_max_amount))
                arr.push(this.createUsageSingle('Folders', this.user.folder.length, plan.folders_max_amount))
                arr.push(this.createUsageSingle('Tasks per list', plan.tasks_max_amount))
                arr.push(this.createUsageSingle('Subtasks per task', plan.subtasks_max_amount))
                arr.push(this.createUsageSingle('Sections per list', plan.sections_max_amount))
                arr.push(this.createUsageSingle('Chats', this.getChats.length, plan.chats_max_amount))
                arr.push(this.createUsageSingle('Log messages', plan.log_max_amount))
                arr.push(this.createUsageSingle('Disk space', this.$w_parse_doc_size(this.getUserUsedFileSize), this.$w_parse_doc_size(this.getUserAllFileSize)))
                return arr
            }
        },
        methods: {
            ...mapActions([
                "change_user_data"
            ]),
            close() {
                this.$emit('close')
            },
            createUsageSingle(name, current, max) {
                return {name, current, max}
            },
            toggleShowDiskUsageInSidebar() {
                this.change_user_data({
                    type: 'showDiskUage',
                    value: !this.showDiskUsageInSidebar
                })
                this.$socket.emit('IN_ShowDiskUsage')
                this.showDiskUsageInSidebar = !this.showDiskUsageInSidebar
            }
        }
    }

</script>